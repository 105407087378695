.App {
  text-align: center;
}
p {
  padding: 0;
  margin: 0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.custom-header {
  background-color: #fff;
  border-bottom: 1px solid #c6c6c6;
}

.custom-card-list {
  display: flex;
  align-items: center;
  gap: 20px;
  /* justify-content: space-between; */
  padding: 40px 24px 40px 40px;
  border-radius: 4px;
  /* border-right: 2px solid var(--brand-color-brand-color-02, #cc200d); */
  background: var(--grayscale-white, #fff);
  box-shadow: 1px 2px 6px 0px rgba(31, 50, 116, 0.06);
  cursor: pointer;
}

.custom-card-list:hover {
  border-radius: 4px;
  background: var(--brand-color-brand-color-02, #cc200d);
  box-shadow: 1px 2px 6px 0px rgba(31, 50, 116, 0.06);
  position: relative;
}
.custom-card-list:hover path {
  stroke: #fff;
}

.custom-card-list:hover > .title-card-list {
  color: #fff;
}
.custom-card-list:hover > .custom-card-list-tag {
  background: #fff !important;
  right: -1px !important;
  top: -1px !important;
}

/* /////////////// */

.menu-left-sider {
  padding: 20px 26px;
  display: flex;
  align-items: center;
  gap: 20px;
  border-bottom: 1px solid #b1b1b1;
  cursor: pointer;
}

.menu-left-sider-active {
  background: #111;
}
.menu-left-sider:hover {
  background: rgb(17, 17, 17, 0.9);
}
.menu-left-sider:hover path {
  stroke: #fff;
}
.menu-left-sider:hover .menu-left-sider__content {
  color: #fff;
}

.menu-left-sider-active path {
  stroke: #fff;
}

.menu-left-sider-active .menu-left-sider__content {
  color: #fff;
}

.menu-right-sider-icon {
  transform: rotate(-90deg);
}
.menu-right-sider-icon-active {
  transform: rotate(0deg);
}
.menu-right-sider-icon path {
  stroke: #838383;
}

.header-mobile {
  display: none;
}
.hiden_block_992 {
  display: none;
}

.hiden_block_1200 {
  display: none;
}
.hiden_block_1600 {
  display: none;
}

@media (min-width: 992px) {
  .hiden_block_992 {
    display: block;
  }
}
@media (max-width: 992px) {
  .header-mobile {
    display: block;
  }
}
@media (min-width: 1200px) {
  .hiden_block_1200 {
    display: block;
  }
}
@media (min-width: 1600px) {
  .hiden_block_1600 {
    display: block;
  }
}
